<template>
  <div :is="organizationData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="organizationData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
      <div class="alert-body">
        {{ $t("message.organization.organizationNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-organization-list' }">
          {{ $t("message.organization.organizationList") }}
        </b-link>
        {{ $t("message.organization.Organization") }}
      </div>
    </b-alert>

    <template v-if="organizationData">
      <div class="cardTableProject">
        <b-card>
          <h2 class="mb-2">
            {{ $t("message.Organization.editOrganization") }}
          </h2>

          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form @submit.prevent="handleSubmit(editOrganization())">
              <b-row cols="12">
                <b-col md="4" class="mt-1">
                  <validation-provider
                    #default="{ errors }"
                    name="company"
                    rules="required"
                  >
                    <b-form-group label-for="nameOpportunities">
                      <template v-slot:label>
                        {{ $t("message.Organization.nameOfYourCompany") }}
                      </template>
                      <b-form-input
                        type="text"
                        v-model="organizationData.company"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- customers -->
                <b-col md="4" class="mt-1">
                  <validation-provider
                    #default="{ errors }"
                    name="costumer"
                    rules="required|email"
                  >
                    <b-form-group label-for="costumer">
                      <template v-slot:label>
                        {{ $t("message.Organization.emailOfCompany") }}
                      </template>
                      <b-form-input
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        v-model="organizationData.emailCompany"
                        type="email"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="2" class="mt-1">
                  <validation-provider
                    #default="{ errors }"
                    name="cnpj"
                    rules="required"
                  >
                    <b-form-group label="CNPJ" label-for="startOpportunities">
                      <b-form-input
                        v-model="organizationData.cnpj"
                        v-mask="'###.###.####.##'"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="2" class="mt-1">
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    rules="required"
                  >
                    <b-form-group label-for="startOpportunities">
                      <template v-slot:label>
                        {{ $t("message.Organization.phone") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.phone"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- end -->
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="responsiblePerson"
                    rules="required"
                  >
                    <b-form-group label-for="opportunityDate">
                      <template v-slot:label>
                        {{ $t("message.Organization.fullResponsible") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.responsiblePerson"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="responsiblePerson"
                    rules="required"
                  >
                    <b-form-group label-for="value">
                      <template v-slot:label>
                        {{ $t("message.Organization.occupation") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.occupation"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="4">
                  <b-form-group label-for="value">
                    <template v-slot:label>
                      {{ $t("message.Organization.stateOfProcess") }}
                    </template>
                    <v-select
                      :options="organizationDataStatus"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="organization-status"
                      v-model="organizationData.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <!-- adress -->
              </b-row>
              <b-row cols="12">
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    name="adress"
                    rules="required"
                  >
                    <b-form-group label-for="value">
                      <template v-slot:label>
                        {{ $t("message.Organization.adress") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.adress"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="3">
                  <validation-provider
                    #default="{ errors }"
                    name="district"
                    rules="required"
                  >
                    <b-form-group label-for="value">
                      <template v-slot:label>
                        {{ $t("message.Organization.district") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.district"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="2">
                  <validation-provider
                    #default="{ errors }"
                    name="city"
                    rules="required"
                  >
                    <b-form-group label-for="value">
                      <template v-slot:label>
                        {{ $t("message.Organization.city") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.city"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="2">
                  <validation-provider
                    #default="{ errors }"
                    name="zipCode"
                    rules="required"
                  >
                    <b-form-group label-for="value">
                      <template v-slot:label>
                        {{ $t("message.Organization.zipCode") }}
                      </template>
                      <b-form-input
                        v-model="organizationData.zipCode"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="1">
                  <validation-provider
                    #default="{ errors }"
                    name="UF"
                    rules="required"
                  >
                    <b-form-group label="UF" label-for="value">
                      <b-form-input
                        v-model="organizationData.uf"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <br />
              <h5>Dados</h5>
              <br />

              <b-row>
                <b-col md="2">
                  <b-form-group label-for="value">
                    <template v-slot:label>
                      {{ $t("dataLimit") }}
                    </template>
                    <b-form-input type="text" v-model="dataLimitMb" />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Spacer -->
              <hr class="invoice-spacing" />

              <b-form-group label-for="wildcard">
                <template v-slot:label> {{ $t("uploadFile") }} </template>
                <b-form-file
                  style="width: 350px"
                  id="wildcard"
                  ref="file-input"
                  class="mr-1"
                  v-model="file"
                  browse-text="Procurar"
                  placeholder="Nenhum arquivo selecionado"
                  drop-placeholder="Arraste arquivo aqui"
                  :accept="fileFormats"
                >
                </b-form-file>
                <b-button
                  style="margin-top: 7px"
                  variant="warning"
                  class="btn-icon mr-1"
                  @click="file = null"
                  v-if="file"
                >
                  {{ $t("clean") }}  <feather-icon icon="RefreshCwIcon" />
                </b-button>
                <b-button
                  style="margin-top: 7px"
                  variant="success"
                  class="btn-icon"
                  @click="addFile()"
                  v-if="file"
                >
                  {{ $t("createFile") }}  <feather-icon icon="SendIcon" />
                </b-button>
              </b-form-group>

              <br />

              <b-row>
                <b-col
                  style="max-width: 200px; !important"
                  v-for="item in filesData"
                  :key="item.id"
                >
                  <b-card
                    text-variant="white"
                    class="text-center"
                    bg-variant="primary"
                  >
                    <feather-icon size="40" icon="FileIcon" />

                    <div class="truncate">
                      <br />
                      <span>{{ item.name }}</span>
                    </div>
                    <br />
                    <b-row>
                      <b-col md="6"
                        ><b-button
                          size="sm"
                          @click="downloadFile(item.id, item.name)"
                          v-if="restrictions('organization_file_download')"
                          ><feather-icon size="20" icon="DownloadCloudIcon"
                        /></b-button>
                      </b-col>

                      <b-col md="6"
                        ><b-button
                          size="sm"
                          v-b-modal.modal-delete-file-confirmation
                          v-if="restrictions('organization_file_delete')"
                          @click="
                            selectedFileId = item.id;
                            selectedFileName = item.name;
                          "
                          ><feather-icon size="20" icon="XIcon"
                        /></b-button>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>

              <b-modal
                id="modal-delete-file-confirmation"
                modal-class="modal-danger"
                centered
                title="Deletar"
                hide-footer
              >
                <b-card-text>
                  Você tem certeza que deseja deletar o arquivo
                  <b>{{ selectedFileName }}</b> ?</b-card-text
                >
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <b-button
                    v-b-modal.modal-danger
                    class="mt-2"
                    variant="danger"
                    @click="deleteFile()"
                  >
                    Deletar
                  </b-button>
                </div>
              </b-modal>

              <b-row>
                <b-col>
                  <div class="text-right mt-2 mb-2">
                    <b-button
                      variant="primary"
                      class="mr-1"
                      @click="editOrganization()"
                    >
                      {{ $t("message.buttons.save") }}
                    </b-button>

                    <b-modal
                      id="modal-success"
                      ok-only
                      ok-variant="success"
                      ok-title="Accept"
                      modal-class="modal-success"
                      centered
                      title="Success Modal"
                    >
                      <b-card-text>
                        Biscuit chocolate cake gummies. Lollipop I love macaroon
                        bear claw caramels. I love marshmallow tiramisu I love
                        fruitcake I love gummi bears. Carrot cake topping
                        liquorice. Pudding caramels liquorice sweet I love.
                        Donut powder cupcake ice cream tootsie roll jelly.
                      </b-card-text>
                    </b-modal>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BCardGroup,
  BCard,
  BModal,
  VBModal,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BForm,
  BFormFile,
} from "bootstrap-vue";
import getHeader from "@core/utils/auth";
import router from "@/router";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import getHeaderFile from "@core/utils/authFile";
import download from "downloadjs";
import AccessControl from "@core/utils/access-control";


export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BCardGroup,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    flatPickr,
    BFormDatepicker,
    BModal,
    BForm,
    BFormFile,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
  },

  data() {
    return {
      sizeFile: 0,

      userData: store.state.user.userData,

      organizationData: {},

      dataLimitMb: "",

      organizationDataStatus: [],

      fileFormats: "",
      fileMaxSize: 0,
      file: null,
      filesData: [],
      selectedFileId: "",
      selectedFileName: "",

      paramData: store.state.user.paramData,
    };
  },

  watch: {
    file: function (newVal, oldVal) {
      if (newVal != null) {
        const maxAllowedSize = this.fileMaxSize * 1024 * 1024;
        if (newVal.size > maxAllowedSize) {
          // Here you can ask your users to load correct file
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Não permitido",
              icon: "XIcon",
              variant: "danger",
              text: "Arquivo com tamanho maior do que permitido pelo sistema",
            },
          });
          this.$refs["file-input"].reset();
        }
      }
    },
  },

  async created() {
    await this.getData();

    var statusList = [];
    var statustId = [];
    await axios
      .get(`${URL_API}organization_status`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].status;
          const id = response.data[i].status;
          statusList.push(element);
          statustId.push(id);
          this.organizationDataStatus.push({
            label: statusList[i],
            value: statustId[i],
          });
        }
      });

    await this.getTaskFilesData();
    this.loadFileFormats();
  },

  methods: {
    async getTaskFilesData() {
      this.files = [];
      await axios({
        method: "get",
        url: `${URL_API}file/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.filesData = response.data;
      });
    },

    restrictions(value) {
      return AccessControl(value);
    },    

    loadFileFormats() {
      this.fileFormats = this.paramData.fileTypes.join(", ").toString();
      this.fileMaxSize = this.paramData.maxFileSize;
    },

    addFile() {
      let formData = new FormData();
      formData.append("file", this.file);

      axios
        .post(
          `${URL_API}file/organization/${this.userData.id}/${router.currentRoute.params.id}`,
          formData,
          {
            headers: getHeaderFile(this.userData),
          }
        )
        .then((response) => {
          if (!response.data.id && response.data != "") {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("noDataAvailable"),
                icon: "XIcon",
                variant: "danger",
                text: this.$i18n.t("noDataAvailableDesc"),
              },
            });
          } else if (response.data === "") {
            this.getTaskFilesData();
            this.file = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
          } else {
            this.getTaskFilesData();
            this.file = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("message.toast.create"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$i18n.t("message.toast.create"),
              },
            });
          }
        });
    },

    deleteFile() {
      axios({
        method: "delete",
        url: `${URL_API}file/${this.selectedFileId}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        if (response.data === "") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("Error"),
              icon: "XIcon",
              variant: "warning",
              text: this.$i18n.t("offline"),
            },
          });
        } else {
          this.getTaskFilesData();
          this.$root.$emit("bv::hide::modal", "modal-delete-file-confirmation");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Deletado com sucesso!",
              icon: "CheckIcon",
              variant: "success",
              text: "Arquivo deletado com sucesso",
            },
          });
        }
      });
    },

    downloadFile(id, name) {
      axios({
        method: "get",
        url: `${URL_API}file/download/${id}`,
        headers: getHeader(this.userData),
        responseType: "blob",
      }).then((response) => {
        if (response.data.size === 0) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("Error"),
              icon: "XIcon",
              variant: "warning",
              text: this.$i18n.t("offline"),
            },
          });
        } else {
          const content = response.headers["content-type"];
          download(response.data, name, content);
        }
      });
    },

    async getData() {
      await axios({
        method: "get",
        url: `${URL_API}organization/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.organizationData = response.data;
        if (this.organizationData.dataLimit != null) {
          var sizeInMB = (
            this.organizationData.dataLimit /
            (1024 * 1024)
          ).toFixed(2);
          this.dataLimitMb = sizeInMB;
        }
      });
    },

    editOrganization() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          var fileSizeInKB = this.dataLimitMb * 1024;
          var fileSizeInMB = fileSizeInKB * 1024;

          this.organizationData.dataLimit = fileSizeInMB;

          axios({
            method: "put",
            url: `${URL_API}organization/${this.organizationData.id}`,
            headers: getHeader(this.userData),
            data: this.organizationData,
          }).then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t("message.toast.updatedB"),
                icon: "CheckIcon",
                variant: "success",
                text: `O cadastro ${this.organizationData.company} foi editado com sucesso!`,
              },
            });

            this.$router.push({ name: "apps-organization-list" });
          });
        }
      });
    },
  },
};
</script>

<style>
.widthTable {
  width: 100%;
}

.widthTd {
  width: 50%;
}

@media screen and (max-width: 767px) {
  .cardTableProject {
    width: 100%;
  }

  .rTable td {
    display: flex;
    flex-direction: column;
  }
  .rTableWidth {
    width: 100%;
  }

  .rButton {
    margin-top: 3rem;
  }
}
</style>
